<template>
  <div class="container-fluid">
    <Header />
    <div
      class="mx-auto mt-1 content-outer-container"
      :class="{ 'content-dashboard': $store.state.isDashboard }"
    >
      <div class="mx-auto content-inner-container">
        <div class="wrap-userCustomerManage">
          <div class="row">
            <div class="col-6 page-header">
              <img
                :src="require('@/assets/images/memberdetailicon.svg')"
                alt=""
              />
              {{ lbl['user-customer-mange-menu-label'] }}
            </div>
            <div class="col-6 page-setting text-right"></div>
          </div>

          <div class="text-center my-3">
            <div class="btn-group" role="group" aria-label="Button group">
              <a-button-group>
                <a-button type="primary">
                  {{ lbl['manage-usr-edit-profile'] }}
                </a-button>
                <a-button @click="goto('UserCustomerLog')">
                  {{ lbl['manage-usr-edit-log'] }}
                </a-button>
              </a-button-group>
            </div>
            <div class="mt-5 p-3 search-box">
              <a-form :form="form" class="search-form">
                <div class="row pb-2">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <label class="text-left w-100">{{
                      lbl['manage-usr-first-name']
                    }}</label>
                    <input
                      v-model="filters.firstName"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <label class="text-left w-100">{{
                      lbl['manage-usr-last-name']
                    }}</label>
                    <input
                      v-model="filters.lastName"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <label class="text-left w-100">{{
                      lbl['manage-usr-contact-number']
                    }}</label>
                    <!-- ใช้ :placeholder="[parameter]" สำหรับแสดงค่าตัวแปร -->
                    <a-input
                      v-model="filters.contactNumber"
                      class="form-control"
                      :max-length="contact_num_max"
                      placeholder=""
                      @change="chcekfillformattel($event)"
                    />
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <label class="text-left w-100">{{
                      lbl['manage-usr-email']
                    }}</label>
                    <input
                      v-model="filters.email"
                      type="text"
                      class="form-control"
                      v-on:keypress="isCheckcharacters($event)"
                    />
                  </div>
                </div>
                <div class="row pb-2">
                  <div
                    v-if="IsReference"
                    class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12"
                  >
                    <label class="text-left w-100">
                      {{ referenceCustomName }}
                    </label>
                    <input
                      v-model="filters.referenceInfo"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  <div
                    v-if="IsReference2"
                    class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12"
                  >
                    <label class="text-left w-100">
                      {{ referenceCustomName2 }}
                    </label>
                    <input
                      v-model="filters.referenceInfo2"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <label class="text-left w-100">{{
                      lbl['import-search-verifycode1']
                    }}</label>
                    <input
                      v-model="filters.verifyCode1"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  <div class="btn-search pr-3 mt-4 text-right">
                    <a-button
                      :disabled="!isDisable()"
                      type="primary"
                      class="width-120"
                      @click="handleSearch()"
                    >
                      {{ lbl['import-search-button'] }}
                    </a-button>
                  </div>
                </div>
              </a-form>
            </div>
            <div v-show="ishandleSearch" class="pt-3 table-box">
              <a-table
                row-key="UserId"
                :columns="columnsDetail"
                :data-source="dataUser"
                :pagination="{
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} ${lbl['pagination-of-text']} ${total}`,
                  pageSizeOptions: pageSizeOptions,
                  pageSize: defaultPageSize,
                  defaultCurrent: 1,
                  showSizeChanger: true,
                  hideOnSinglePage: false,
                  onChange: onChangePageDetail,
                }"
              >
                <template slot="isdata" slot-scope="text">
                  {{ text ? text : '-' }}
                </template>
                <template slot="actions" slot-scope="text, record, index">
                  <span v-if="record.UserId">
                    <a-button
                      v-if="!record.Edit"
                      type="primary"
                      class=""
                      @click="isEditRecord(record)"
                    >
                      {{ lbl['usr-level-edit'] }}
                    </a-button>
                    <div class="d-flex">
                      <a-button
                        v-if="record.Edit"
                        type="primary"
                        class="mr-1"
                        :disabled="
                          edit[index].FirstName == '' ||
                          edit[index].LastName == ''
                        "
                        @click="isSave(index)"
                      >
                        {{ lbl['usr-level-save'] }}
                      </a-button>
                      <a-button
                        v-if="record.Edit"
                        type="danger"
                        class=""
                        @click="isCancelEditRecord(index)"
                      >
                        {{ lbl['usr-level-cancel'] }}
                      </a-button>
                    </div>
                  </span>
                </template>
                <template slot="LevelName" slot-scope="text, record, index">
                  <a-input
                    v-model="edit[index].LevelName"
                    type="text"
                    class="form-control width-for-edit"
                    placeholder=""
                  />
                  <span v-if="!record.LevelName"> {{ text ? text : '-' }}</span>
                </template>

                <template slot="FirstName" slot-scope="text, record, index">
                  <a-input
                    v-if="record.Edit"
                    v-model="edit[index].FirstName"
                    type="text"
                    class="form-control width-for-edit"
                    @keypress="onlyPass"
                    placeholder=""
                  />
                  <span v-if="!record.Edit"> {{ text ? text : '-' }}</span>
                </template>
                <template slot="LastName" slot-scope="text, record, index">
                  <a-input
                    v-if="record.Edit"
                    v-model="edit[index].LastName"
                    type="text"
                    class="form-control width-for-edit"
                    @keypress="onlyPass"
                    placeholder=""
                  />
                  <span v-if="!record.Edit"> {{ text ? text : '-' }}</span>
                </template>
                <template slot="Email" slot-scope="text, record, index">
                  <a-input
                    v-if="record.Edit"
                    v-model="edit[index].Email"
                    type="text"
                    class="form-control width-for-edit"
                    placeholder=""
                  />
                  <span v-if="!record.Edit"> {{ text ? text : '-' }}</span>
                </template>
                <template slot="ContactNumber" slot-scope="text, record, index">
                  <a-input
                    v-if="record.Edit"
                    v-model="edit[index].ContactNumber"
                    type="number"
                    class="form-control"
                    placeholder=""
                    @change="chcekformattel($event, index)"
                  />
                  <span v-if="!record.Edit"> {{ text ? text : '-' }}</span>
                </template>
                <template
                  slot="isshowcolumnsPoint"
                  slot-scope="text, record, index"
                >
                  <a-input-number
                    v-if="record.Edit"
                    v-model="edit[index].Point"
                    :min="0"
                    :formatter="
                      value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    "
                    :parser="value => value.replace(/[^0-9]/g, '')"
                    class="form-control"
                    :disabled="isBeeBanefit"
                  />
                  <span v-if="!record.Edit">
                    {{
                      record.Point ? record.Point.toLocaleString() : '-'
                    }}</span
                  >
                </template>
                <template slot="ReferenceInfo" slot-scope="text, record, index">
                  <a-input
                    v-if="record.Edit"
                    v-model="edit[index].ReferenceInfo"
                    type="text"
                    class="form-control width-for-edit"
                    placeholder=""
                  />
                  <span v-if="!record.Edit"> {{ text ? text : '-' }}</span>
                </template>
                <template
                  slot="ReferenceInfo2"
                  slot-scope="text, record, index"
                >
                  <a-input
                    v-if="record.Edit"
                    v-model="edit[index].ReferenceInfo2"
                    type="text"
                    class="form-control width-for-edit"
                    placeholder=""
                  />
                  <span v-if="!record.Edit"> {{ text ? text : '-' }}</span>
                </template>
                <template slot="Gender" slot-scope="text, record, index">
                  <div>
                    <a-select
                      v-if="record.Edit"
                      v-model="edit[index].Gender"
                      :default-value="record.Gender"
                      style="width: 120px"
                    >
                      <a-select-option value="male"> Male </a-select-option>
                      <a-select-option value="female"> Female </a-select-option>
                    </a-select>
                  </div>
                  <span v-if="!record.Edit">
                    <span v-if="record.Gender == 'male'">{{
                      lbl['Male']
                    }}</span>
                    <span v-else-if="record.Gender == 'female'">{{
                      lbl['Female']
                    }}</span>
                    <span v-else>{{ lbl['Other'] }}</span>
                  </span>
                </template>
                <template slot="Active" slot-scope="text, record, index">
                  <div>
                    <a-switch
                      v-if="record.Edit"
                      v-model="edit[index].Active"
                      :default-value="record.Active"
                    />
                  </div>
                  <span v-if="!record.Edit">
                    {{ record.Active ? lbl['active'] : lbl['inactive'] }}</span
                  >
                </template>
                <template slot="BirthDate" slot-scope="text, record, index">
                  <div>
                    <a-date-picker
                      v-if="record.Edit"
                      style="width: 120px"
                      v-model="edit[index].BirthDate"
                      :format="dateFormat"
                    />
                    <span v-if="!record.Edit">
                      {{ text ? dateFormate(text) : '-' }}</span
                    >
                  </div>
                </template>
                <template slot="LevelName" slot-scope="text, record, index">
                  <div>
                    <a-select
                      v-if="record.Edit && hasUserlevel"
                      v-model="edit[index].LevelName"
                      :default-value="record.LevelName"
                    >
                      <a-select-option
                        v-for="(option, keyOption) in userLevel"
                        :key="keyOption"
                        :value="option.UserLevelName"
                      >
                        {{ option.UserLevelName }}
                      </a-select-option>
                    </a-select>
                  </div>
                  <span v-if="!record.Edit || !hasUserlevel">
                    {{ text ? Checkdata(text) : '-' }}</span
                  >
                </template>
                <template slot="RegisterDate" slot-scope="text">
                  <span> {{ text ? dateFormate(text) : '-' }}</span>
                </template>
                <template slot="TermAndCondition" slot-scope="text">
                  <span> {{ text ? text : '-' }}</span>
                </template>
                <template slot="DataPrivacy" slot-scope="text">
                  <span> {{ text ? text : '-' }}</span>
                </template>
                <template slot="LineMarketing" slot-scope="text">
                  <span> {{ text ? text : '-' }}</span>
                </template>
                <template slot="SMSMarketing" slot-scope="text">
                  <span> {{ text ? text : '-' }}</span>
                </template>
                <template slot="EmailMarketing" slot-scope="text">
                  <span> {{ text ? text : '-' }}</span>
                </template>
              </a-table>
              <div class="error-Msg">{{ messageOFError }}</div>
              <!-- <div class="box-pagination">
                <div class="show-page">
                  {{ lbl['pagination-show-text'] }} {{ currentDetail }}
                  {{ lbl['pagination-to-text'] }}
                  {{ Math.ceil(totalDetail / 10) }}
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <AlertModal
      :isOpenModal="alertModalSetting.isOpenModal"
      :modalType="alertModalSetting.modalType"
      :title="alertModalSetting.title"
      :details="alertModalSetting.details"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import Account from '@/helper/AccountHelper.js'
import Vue from 'vue'
import Mixin from '@/mixin/Mixin'
import Cache from '@/helper/Cache.js'
import BzbsImport from '@/core/Import/service/BzbsImport'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import dayjs from 'dayjs'
import moment from 'moment'
import Header from '@/components/Layout/Header'
import Locale from '@/helper/locale.js'
import AlertModal from '@/components/Modal/Component/alertmodal2'

export default {
  name: 'userCustomerManage',
  mixins: [Mixin],
  components: {
    Header,
    AlertModal,
  },
  data: function () {
    return {
      dataUser: [],
      userLevel: [],
      edit: [],
      total: null,
      pageSizeOptions: ['20', '50', '100'],
      defaultPageSize: 20,
      columnsDetail: [
        {
          title: 'UserID',
          scopedSlots: {
            customRender: 'actions',
          },
        },
        {
          title: 'UserID',
          dataIndex: 'UserId',
          key: 'import-log-userid-header',
          width: 300,
          scopedSlots: {
            customRender: 'isdata',
          },
        },
        {
          title: 'First Name',
          dataIndex: 'FirstName',
          key: 'manage-usr-first-name',
          width: 200,
          align: 'left',
          scopedSlots: {
            customRender: 'FirstName',
          },
        },
        {
          title: 'LastName',
          dataIndex: 'LastName',
          key: 'manage-usr-last-name',
          align: 'left',
          width: 200,
          scopedSlots: {
            customRender: 'LastName',
          },
        },
        {
          title: 'Email',
          dataIndex: 'Email',
          key: 'manage-usr-email',
          align: 'left',
          width: 200,
          scopedSlots: {
            customRender: 'Email',
          },
        },
        {
          title: 'Mobile Number',
          dataIndex: 'ContactNumber',
          key: 'manage-usr-contact-number',
          width: 180,
          scopedSlots: {
            customRender: 'ContactNumber',
          },
        },
        {
          title: 'Status',
          dataIndex: 'Active',
          key: 'plan-reward-point-earn-manage-status',
          scopedSlots: {
            customRender: 'Active',
          },
        },
        {
          title: 'Reference Info',
          dataIndex: 'ReferenceInfo',
          key: 'manage-usr-reference-number',
          width: 200,
          scopedSlots: {
            customRender: 'ReferenceInfo',
          },
        },
        {
          title: 'Reference Info2',
          dataIndex: 'ReferenceInfo2',
          key: 'manage-usr-reference-number2',
          width: 200,
          scopedSlots: {
            customRender: 'ReferenceInfo2',
          },
        },
        {
          title: 'Gender',
          dataIndex: 'Gender',
          key: 'manage-usr-gender',
          scopedSlots: {
            customRender: 'Gender',
          },
        },
        {
          title: 'BirthDate',
          dataIndex: 'BirthDate',
          key: 'manage-usr-birth-date',
          width: 120,
          scopedSlots: {
            customRender: 'BirthDate',
          },
        },
        {
          title: 'UserLevel',
          dataIndex: 'LevelName',
          key: 'user-level-header',
          width: 120,
          scopedSlots: {
            customRender: 'LevelName',
          },
        },
        {
          title: 'Register Date',
          dataIndex: 'RegisterDate',
          key: 'register-date-header',
          width: 120,
          scopedSlots: {
            customRender: 'RegisterDate',
          },
        },
        {
          title: 'Term and Condition',
          dataIndex: 'TermAndCondition',
          key: 'dashboard-insight-user-info-term-and-condition',
          width: 180,
          scopedSlots: {
            customRender: 'TermAndCondition',
          },
        },
        {
          title: 'Privacy Policy',
          dataIndex: 'DataPrivacy',
          key: 'dashboard-insight-user-info-privacy-policy',
          width: 180,
          scopedSlots: {
            customRender: 'DataPrivacy',
          },
        },
        {
          title: 'LINE Marketing',
          dataIndex: 'LineMarketing',
          key: 'dashboard-insight-user-info-line-marketing',
          width: 150,
          scopedSlots: {
            customRender: 'LineMarketing',
          },
        },
        {
          title: 'SMS Marketing',
          dataIndex: 'SMSMarketing',
          key: 'dashboard-insight-user-info-sms-marketing',
          width: 150,
          scopedSlots: {
            customRender: 'SMSMarketing',
          },
        },
        {
          title: 'Email Marketing',
          dataIndex: 'EmailMarketing',
          key: 'dashboard-insight-user-info-email-marketing',
          width: 150,
          scopedSlots: {
            customRender: 'EmailMarketing',
          },
        },
      ],
      totalDetail: null,
      currentDetail: 1,
      messageOFError: '',
      filters: {
        firstName: '',
        lastName: '',
        contactNumber: '',
        email: '',
        referenceInfo: '',
        referenceInfo2: '',
        verifyCode1: '',
      },
      dateFormat: 'DD MMM YYYY',
      contact_num_max: 10,
      referenceCustomName: '',
      referenceCustomName2: '',
      currentLocale: 'th',
      alertModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        isOpenModal: false,
      },
      isBeeBanefit: false,
      IsReference: true,
      IsReference2: true,
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'import-user-search',
      onFieldsChange: prop => {
        if (prop.isFieldsTouched()) {
          this.formTouch = true
        }
      },
    })
  },
  created() {
    this.handleFooter(true)
    Account.bzbsAnalyticTracking(
      'member_details_page',
      'member_details',
      'view_member_details',
      'on view',
    )
    var crmPackage = Account.getCachePackage()
    if (crmPackage && crmPackage.PackageId) {
      this.isBeeBanefit = crmPackage.PackageId.includes('benefit')
    }
    if (this.isBeeBanefit) {
      this.columnsDetail = [
        {
          title: 'UserID',
          scopedSlots: {
            customRender: 'actions',
          },
        },
        {
          title: 'UserID',
          dataIndex: 'UserId',
          key: 'import-log-userid-header',
          width: 300,
          scopedSlots: {
            customRender: 'isdata',
          },
        },
        {
          title: 'First Name',
          dataIndex: 'FirstName',
          key: 'manage-usr-first-name',
          width: 200,
          align: 'left',
          scopedSlots: {
            customRender: 'FirstName',
          },
        },
        {
          title: 'LastName',
          dataIndex: 'LastName',
          key: 'manage-usr-last-name',
          align: 'left',
          width: 200,
          scopedSlots: {
            customRender: 'LastName',
          },
        },
        {
          title: 'Email',
          dataIndex: 'Email',
          key: 'manage-usr-email',
          align: 'left',
          width: 200,
          scopedSlots: {
            customRender: 'Email',
          },
        },
        {
          title: 'Mobile Number',
          dataIndex: 'ContactNumber',
          key: 'manage-usr-contact-number',
          width: 180,
          scopedSlots: {
            customRender: 'ContactNumber',
          },
        },
        {
          title: 'Status',
          dataIndex: 'Active',
          key: 'plan-reward-point-earn-manage-status',
          scopedSlots: {
            customRender: 'Active',
          },
        },
        {
          title: 'Gender',
          dataIndex: 'Gender',
          key: 'manage-usr-gender',
          scopedSlots: {
            customRender: 'Gender',
          },
        },
        {
          title: 'BirthDate',
          dataIndex: 'BirthDate',
          key: 'manage-usr-birth-date',
          width: 120,
          scopedSlots: {
            customRender: 'BirthDate',
          },
        },
        {
          title: 'Register Date',
          dataIndex: 'RegisterDate',
          key: 'register-date-header',
          width: 120,
          scopedSlots: {
            customRender: 'RegisterDate',
          },
        },
      ]
    }
    this.ishandleSearch = false
    this.contact_num_max = Vue.bzbsConfig.businessRule.max.phoneLength
    this.getCRMPlusProfile()
      .then(result => {
        console.log(result)
        this.levelModeCRMPlusProfile = result.levelmode
      })
      .then(() => {
        if (Vue.bzbsConfig.businessRule.webFor.PH) {
          this.columnsDetail = _.forEach(
            this.columnsDetail,
            (column, index) => {
              if (column) {
                if (column.dataIndex) {
                  if (column.dataIndex == 'LineMarketing') {
                    this.columnsDetail.splice(index, 1)
                  }
                }
              }
            },
          )
          this.setLocale()
        } else {
          this.setLocale()
        }
      })
    for (let i = 0; i < this.columnsDetail.length; i++) {
      if (this.columnsDetail[i].dataIndex == 'Point') {
        this.storeTypePoint = true
      }
    }
    if (Cache.get('CRMUser').StoreType == 'point' && !this.storeTypePoint) {
      this.columnsDetail.splice(7, 0, {
        title: 'Point',
        dataIndex: 'Point',
        key: 'plan-reward-point-burn-manage-point-unit',
        width: 150,
        scopedSlots: {
          customRender: 'isshowcolumnsPoint',
        },
      })
    }
    if (Cache.get('CRMUser').hasUserlevel == 'true') {
      this.hasUserlevel = true
    } else {
      this.hasUserlevel = false
    }
  },
  mounted() {
    this.currentLocale = Locale.getLocaleShort()
  },
  methods: {
    moment,
    setLocale() {
      setTimeout(() => {
        _.forEach(this.columnsDetail, column => {
          var customTitle = null
          if (this.lbl[column.key] == this.lbl['manage-usr-reference-number']) {
            var titleRef1 = this.referenceCustomName
              ? this.referenceCustomName
              : this.lbl['manage-usr-reference-number']
            customTitle = titleRef1
          }
          if (
            this.lbl[column.key] == this.lbl['manage-usr-reference-number2']
          ) {
            var titleRef2 = this.referenceCustomName2
              ? this.referenceCustomName2
              : this.lbl['manage-usr-reference-number2']
            customTitle = titleRef2
          }
          column.title = customTitle ? customTitle : this.lbl[column.key]
        })
      }, 500)
    },
    tagAnalytic(page) {
      Account.bzbsAnalyticTracking(
        'member_details_page',
        'member_details',
        'click_' + page + 'tab',
        'on click',
      )
    },
    alertModalAction(title, details, modalType, isOpenModal) {
      this.alertModalSetting.title = title
      this.alertModalSetting.details = details
      this.alertModalSetting.modalType = modalType
      this.alertModalSetting.isOpenModal = isOpenModal
      if (modalType == 'success' && isOpenModal) {
        setTimeout(() => {
          this.alertModalSetting.isOpenModal = false
        }, 3000)
      } else {
        if (isOpenModal)
          setTimeout(() => {
            this.alertModalSetting.isOpenModal = false
          }, 3000)
      }
    },
    onlyPass($event) {
      if ($event.keyCode != 32) {
        const regex_en = /^[A-Z a-z]\d*$/
        const regex_th = /^[ก-๙]\d*$/
        var match_en = $event.key.match(regex_en)
        var match_th = $event.key.match(regex_th)

        if (match_en == null && match_th == null) {
          $event.preventDefault()
        }
      } else {
        $event.preventDefault()
      }
    },
    isDisable() {
      if (
        this.filters.firstName.length >= 2 ||
        this.filters.lastName.length >= 2 ||
        this.filters.contactNumber.length >= this.contact_num_max ||
        this.filters.email.length >= 2 ||
        this.filters.referenceInfo.length >= 2 ||
        this.filters.referenceInfo2.length >= 2 ||
        this.filters.verifyCode1.length >= 2
      ) {
        return true
      } else {
        return false
      }
    },
    onChangePageDetail(page) {
      this.currentDetail = page
      this.getSearchUserInfoFunc((page - 1) * 10)
    },
    handleSearch() {
      this.dataUser = []
      this.edit = []
      this.messageOFError = ''
      this.currentDetail = 1
      Account.bzbsAnalyticTracking(
        'member_details_page',
        'member_details',
        'click_member_search',
        'on click',
      )
      this.getSearchUserInfoFunc().then(response => {
        if (response.length > 0) {
          this.totalDetail = response.length
          for (let i = 0; i < this.totalDetail; i++) {
            if (response[i].userid) {
              this.getuserByUserId(response[i].userid).then(res => {
                res.Edit = false
                this.dataUser.push(res)

                this.edit.push({
                  UserId: res.UserId,
                  FirstName: res.FirstName,
                  LastName: res.LastName,
                  Email: res.Email,
                  ContactNumber: res.ContactNumber,
                  ReferenceInfo: res.ReferenceInfo,
                  ReferenceInfo2: res.ReferenceInfo2,
                  Gender: res.Gender,
                  Active: res.Active,
                  Point: res.Point,
                  Level: res.Level,
                  LevelName: res.LevelName,
                  RegisterDate: res.RegisterDate,
                  BirthDate: res.BirthDate
                    ? dayjs(res.BirthDate * 1000).format(this.dateFormat)
                    : null,
                  TermAndCondition: res.TermAndCondition,
                  DataPrivacy: res.DataPrivacy,
                  LineMarketing: res.LineMarketing,
                  SMSMarketing: res.SMSMarketing,
                  EmailMarketing: res.EmailMarketing,
                })
                this.handleLoading(false)
              })
            } else {
              this.handleLoading(false)

              this.dataUser.push({
                Edit: false,
                UserId: response[0].userid,
                FirstName: response[0].name,
                LastName: response[0].surname,
                Email: response[0].email,
                ContactNumber: response[0].mobilenumber,
                ReferenceInfo: response[0].reference_info,
                ReferenceInfo2: response[0].reference_info2,
                Gender: response[0].gender,
                Active: null,
                Point: null,
                Level: null,
                LevelName: null,
                RegisterDate: null,
                BirthDate: null,
                TermAndCondition: null,
                DataPrivacy: null,
                LineMarketing: null,
                SMSMarketing: null,
                EmailMarketing: null,
              })
            }
          }

          let data = _.filter(this.dataUser, item => {
            if (item.UserId == undefined || item.UserId == null) return item
          })
          if (data.length > 0) {
            this.messageOFError = this.lbl['condition-edit-import-user']
          } else {
            this.messageOFError = null
          }
        } else {
          this.handleLoading(false)
        }
      })
      this.ishandleSearch = true
    },
    chcekformattel(event, index) {
      var value = event.target.value
      if (value.length > 10) {
        this.edit[index].ContactNumber = this.edit[
          index
        ].ContactNumber.substring(10, 0)
      }
    },
    chcekfillformattel(event) {
      var value = event.target.value
      if (value.length > this.contact_num_max) {
        this.filters.contactNumber = this.filters.contactNumber.substring(
          this.contact_num_max,
          0,
        )
      }
    },
    isEditRecord(item) {
      this.messageOFError = ''
      item.Edit = true
      var mode = this.levelModeCRMPlusProfile
        ? this.levelModeCRMPlusProfile
        : ''
      console.log(mode)
      this.getcrmPlusLevel(mode).then(res => {
        if (res.length) {
          this.userLevel = res
        }
        this.handleLoading(false)
      })
    },
    Checkdata(text) {
      if (text == '' || text == 'null' || text == null) {
        text = '-'
      }
      return text
    },
    isCancelEditRecord(index) {
      this.messageOFError = ''
      _.forEach(this.edit[index], (value, key) => {
        if (key == 'BirthDate') {
          moment.locale('en')
          this.edit[index][key] = this.dataUser[index][key]
            ? dayjs(this.dataUser[index][key] * 1000).format(this.dateFormat)
            : null
          console.log('this.edit : ', this.edit)
        } else {
          this.edit[index][key] = this.dataUser[index][key]
        }
      })
      this.dataUser[index].Edit = false
    },
    isSave(index) {
      this.messageOFError = ''
      this.handleLoading(true)
      // _.forEach(this.edit[index], (value, key) => {
      //   this.dataUser[index][key] = value
      // })
      this.postUpdateUserByUserId(this.edit[index]).then(res => {
        if (res.UserId) {
          moment.locale('en')
          this.dataUser[index].Active = res.Active
          this.dataUser[index].BirthDate = res.BirthDate
          this.dataUser[index].ContactNumber = res.ContactNumber
          this.dataUser[index].Email = res.Email
          this.dataUser[index].FirstName = res.FirstName
          this.dataUser[index].Gender = res.Gender
          this.dataUser[index].LastName = res.LastName
          this.dataUser[index].Level = res.Level
          this.dataUser[index].LevelName =
            !res.LevelName || res.LevelName == 'null' ? '' : res.LevelName
          this.dataUser[index].RegisterDate = res.RegisterDate
          this.dataUser[index].Point = res.Point
          this.dataUser[index].ReferenceInfo = res.ReferenceInfo
          this.dataUser[index].ReferenceInfo2 = res.ReferenceInfo2
          this.dataUser[index].TermAndCondition = res.TermAndCondition
          this.dataUser[index].DataPrivacy = res.DataPrivacy
          this.dataUser[index].LineMarketing = res.LineMarketing
          this.dataUser[index].SMSMarketing = res.SMSMarketing
          this.dataUser[index].EmailMarketing = res.EmailMarketing
          this.edit[index].BirthDate = res.BirthDate
            ? dayjs(res.BirthDate * 1000).format(this.dateFormat)
            : null

          console.log('edit ; ', this.edit)

          setTimeout(() => {
            this.dataUser[index].Edit = false
            this.handleLoading(false)
          }, 500)
        } else {
          this.handleLoading(false)
        }
      })
    },
    getSearchUserInfoFunc() {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsImport.getSearchUserInfoFunc(
          this.filters.firstName,
          this.filters.lastName,
          this.filters.contactNumber,
          this.filters.email,
          this.filters.referenceInfo,
          this.filters.referenceInfo2,
          this.filters.verifyCode1,
        )
          .then(res => {
            resolve(res.data)
          })
          .catch(error => {
            console.log('getSearchUserInfoFunc error', error)
            resolve(error)
          })
      })
    },
    getuserByUserId(userId) {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsImport.getuserByUserId(userId)
          .then(res => {
            resolve(res.data)
          })
          .catch(error => {
            console.log('apiUserByUserId error', error)
            resolve(error)
          })
      })
    },
    postUpdateUserByUserId(userInfo) {
      return new Promise(resolve => {
        if (userInfo.BirthDate) {
          if (userInfo.BirthDate != 'Invalid date') {
            userInfo.BirthDate = userInfo.BirthDate
              ? moment(userInfo.BirthDate).utc().format('X')
              : ''
          } else {
            userInfo.BirthDate = ''
          }
        }
        if (userInfo.RegisterDate) {
          if (userInfo.RegisterDate != 'Invalid date') {
            userInfo.RegisterDate = userInfo.RegisterDate
              ? moment(userInfo.RegisterDate).utc().format('X')
              : ''
          } else {
            userInfo.RegisterDate = ''
          }
        }
        if (userInfo.ContactNumber == null) {
          userInfo.ContactNumber = ''
        }
        if (userInfo.Email == null) {
          userInfo.Email = ''
        }
        if (userInfo.FirstName == null) {
          userInfo.FirstName = ''
        }
        if (userInfo.LastName == null) {
          userInfo.LastName = ''
        }
        if (userInfo.Gender == null) {
          userInfo.Gender = ''
        }
        if (userInfo.Level == null) {
          userInfo.Level = ''
        }
        if (userInfo.LevelName == null) {
          userInfo.LevelName = ''
        }
        if (userInfo.Point == null) {
          userInfo.Point = ''
        }
        if (userInfo.ReferenceInfo == null) {
          userInfo.ReferenceInfo = ''
        }
        if (userInfo.ReferenceInfo2 == null) {
          userInfo.ReferenceInfo2 = ''
        }
        if (userInfo.UserId == null) {
          userInfo.UserId = ''
        }
        if (userInfo.TermAndCondition == null) {
          userInfo.TermAndCondition = ''
        }
        if (userInfo.DataPrivacy == null) {
          userInfo.DataPrivacy = ''
        }
        if (userInfo.LineMarketing == null) {
          userInfo.LineMarketing = ''
        }
        if (userInfo.SMSMarketing == null) {
          userInfo.SMSMarketing = ''
        }
        if (userInfo.EmailMarketing == null) {
          userInfo.EmailMarketing = ''
        }
        BzbsImport.postUpdateUserByUserId(userInfo)
          .then(res => {
            if (res.status == '200') {
              this.handleLoading(false)
              this.alertModalAction(
                this.lbl['alert-box-success-header'],
                this.lbl['alert-box-success-waiting-15-minutes-message'],
                'success',
                true,
              )
            }
            resolve(res.data)
          })
          .catch(error => {
            var response_Error = error.response.data.error
            this.messageOFError = response_Error.message
            resolve(error)
          })
      })
    },
    getcrmPlusUserLevel(mode) {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsImport.getcrmPlusUserLevel(mode)
          .then(res => {
            resolve(res.data)
          })
          .catch(error => {
            console.log('apiUserByUserId error', error)
            resolve(error)
          })
      })
    },
    getcrmPlusLevel(mode) {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsImport.getcrmPlusLevel(mode)
          .then(res => {
            resolve(res.data)
          })
          .catch(error => {
            console.log('apiCrmPlusLevelList error', error)
            resolve(error)
          })
      })
    },
    getCRMPlusProfile() {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            if (this.isBeeBanefit) {
              this.IsReference = res.data.IsReference
              this.IsReference2 = res.data.IsReference2
            }
            this.setReferenceCustom(res.data)
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('apiGetAppCategoryList error', error)
            resolve(error)
          })
      })
    },
    setReferenceCustom(data) {
      if (
        data.ReferenceCustomName === '' ||
        data.ReferenceCustomName === null ||
        data.ReferenceCustomName === 'null'
      ) {
        this.referenceCustomName = this.lbl['manage-usr-reference-number']
      }
      if (
        data.ReferenceCustomName2 === '' ||
        data.ReferenceCustomName2 === null ||
        data.ReferenceCustomName2 === 'null'
      ) {
        this.referenceCustomName2 = this.lbl['manage-usr-reference-number2']
      }
      // U-354168 Production issue
      if (data.ReferenceCustomName) {
        if (this.currentLocale == 'th') {
          this.referenceCustomName = JSON.parse(
            data.ReferenceCustomName,
          ).name_th
          this.referenceCustomName2 = JSON.parse(
            data.ReferenceCustomName2,
          ).name_th
        } else {
          this.referenceCustomName = JSON.parse(
            data.ReferenceCustomName,
          ).name_en
          this.referenceCustomName2 = JSON.parse(
            data.ReferenceCustomName2,
          ).name_en
        }
      }
    },
    isCheckcharacters(e) {
      let char = String.fromCharCode(e.keyCode)
      if (/^[a-zA-Z0-9@.-_]+$/.test(char)) return true
      else e.preventDefault()
    },
    dateFormate(timestamp) {
      moment.locale('en')
      var strDate = moment.unix(timestamp).utc().format('DD MMM ')
      var year = moment.unix(timestamp).utc().format('YYYY')
      return strDate + year
    },
    goto(page) {
      Account.bzbsAnalyticTracking(
        'member_details_page',
        'member_details',
        'click_member_details_log_page_tab',
        'on click',
      )
      this.$router.push({ name: page })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/common.scss';

.wrap-userCustomerManage {
  .form-control {
    height: 40px;
    font-size: 16px;
  }
  .width-for-edit {
    width: fit-content;
  }
  .search-box {
    border-left: 1px solid #aaa;
    border-top: 2px solid black;
    border-right: 1px solid #aaa;
    border-bottom: 1px solid #aaa;
    border-radius: 0.5em;
  }

  .text-left {
    text-align: left;
  }

  .width-120 {
    width: 120px;
  }

  .ico-excel {
    background-color: #217346;
    height: 1.5vw;
    padding: 0.25vw;
  }

  .button-import {
    height: 45px;
    padding: 5px;
    width: 130px;
    background-color: #ebecf0;
    border-color: #ebecf0;
    color: #000;
  }

  .button-import:hover {
    background-color: #e5e5e5;
    border-color: #e5e5e5;
  }

  .div-import {
    float: right;
  }
  .error-Msg {
    margin-top: 20px;
    color: red;
    font-size: 18px;
    font-weight: 500;
  }
  .ant-btn.ant-btn-primary[disabled] {
    color: #fff;
    background-color: #c4c4c4;
    border-color: #c4c4c4;
  }
  .ant-table-wrapper {
    .ant-table-pagination {
      .ant-pagination {
        margin: 16px 0;
      }
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      border-color: #d9d9d9;
      color: rgba(0, 0, 0, 0.65);
      font-size: 10px;
      &:hover {
        color: $primary-hover !important;
        border-color: $primary-hover !important;
      }
    }

    .ant-select-dropdown-menu-item:hover,
    .ant-select-dropdown-menu-item-selected {
      background-color: #fff7e6;
    }

    .ant-pagination-item-active,
    .ant-pagination-item:focus,
    .ant-pagination-item:hover {
      border-color: $primary-hover !important;
    }

    i.anticon.anticon-left {
      vertical-align: 0;
    }

    i.anticon.anticon-right {
      vertical-align: 0;
    }
  }
}
.btn-search {
  display: flex;
  flex-grow: 1;
  justify-content: right;
}
</style>

<style lang="scss">
.wrap-userCustomerManage {
  .ant-table-wrapper {
    white-space: nowrap;
  }
}
</style>
